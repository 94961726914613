@import "~@pearlchain/powergrid/web/powergrid.css";

.common-grid {
    position: absolute;
    left: 0.5rem;
    right: 0.5rem;
    top: 0;
    bottom: 0;
}

.pg-columnheaders {
    background-color: $pg-color-bg-columnheader;
}

.pg-row.pg-headerrow,
.pg-columnheader,
.pg-groupheader,
.pg-columnheaders > .pg-container {
    background-color: $pg-color-bg-columnheader !important;
    color: $body-color;
    font-weight: bold;
    border-right: 1px solid $pg-color-columnheader-border;
}

.pg-container.pg-fixed.pg-left {
    border-right: 2px solid rgba($pg-color-columnheader-border, 0.25) !important;
}

.pg-rowgroup.pg-scrolling {
    background-color: $pg-color-bg-cell;
    border-top: 2px solid $secondary;
}

.pg-subview {
    padding: 10px;
}

.pg-cell {
    background-color: $pg-color-bg-cell;
    border: 1px solid $pg-color-border-cell;
    padding: 5px 0.5rem;
    text-align: left;
}

.powergrid .btn {
    line-height: 15px;
}

.powergrid .btn.fa {
    // font-awesome requires a specific font-weight in order
    // for the icons to show up
    font-weight: 900;
}

.pg-grouping-groupindicator > .btn-group,
.pg-cell > .btn-group {
    margin-top: -4px;
    margin-right: 4px;
}

.pg-filter-pane {
    background-color: $pg-color-bg-columnheader;
}

.pg-filter-pane:before {
    border-left-color: $pg-color-columnheader-border;
    border-bottom-color: $pg-color-columnheader-border;
}

ul.pg-menu > li:hover {
    background-color: #c4c4c4;
}

.pg-filter {
    font-family: "Glyphicons Halflings" !important;
}

.pg-rowgroup.pg-scrolling {
    background-color: $pg-color-bg;
}

.pg-group-indicator {
    background: $pg-color-bg-columnheader;
}

.pg-group-indicator:before,
.pg-group-indicator:after {
    border-color: $pg-color-bg-columnheader;
}

.pg-hover > .pg-container > .pg-cell:not(.pg-editable) {
    background-color: $pg-color-bg-cell-hover;
}

.pg-hover > .pg-cell:not(.pg-editable),
.pg-hover > .pg-inner-row > .pg-cell:not(.pg-editable) {
    background-color: $pg-color-bg-cell-hover;
}

.pg-hover > .pg-cell.pg-editable,
.pg-hover > .pg-inner-row > .pg-cell.pg-editable {
    background-color: white;
    border: 1px solid $pg-color-bg-cell-hover-editable;
}

.pg-selected > .pg-cell.pg-editable,
.pg-selected > .pg-inner-row > .pg-cell.pg-editable {
    background-color: white;
    border: $pg-cell-selected-border;
}

.pg-hover > .pg-cell.pg-editable + .pg-cell.pg-editable,
.pg-selected > .pg-cell.pg-editable + .pg-cell.pg-editable {
    border-left: none;
}

.pg-selected > .pg-cell:not(.pg-editable),
.pg-selected > .pg-inner-row > .pg-cell:not(.pg-editable) {
    background-color: $pg-color-bg-cell-selected;
    border-top: $pg-cell-selected-border;
    border-bottom: $pg-cell-selected-border;
}

.pg-cell > input[type=radio],
.pg-cell > input[type=checkbox] {
    vertical-align: top;
    margin-right: 4px;
}

/*CUSTOM*/

/* START - grid debug extension css*/
.balloon {
    position: absolute;
    border: 1px solid black;
    border-radius: 4px;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.5);
    z-index: 1000;
    background: rgba(255,255,205,0.7);
    padding: 5px;
    font-size: 8pt;
}

.balloon var {
    font-weight: bold;
}

.balloon p {
    margin: 2px;
}

.grid-debug div.debug-dependency {
    box-shadow: 0px 0px 5px green inset;
}

.grid-debug div.debug-reversedependency {
    box-shadow: 0px 0px 5px red inset;
}

.grid-debug div.debug-selected {
    box-shadow: 0px 0px 5px blue inset;
}

.grid-debug div.debug-error {
    -webkit-animation: debugerror 1s ease 0 infinite alternate;
    animation: debugerror 1s ease 0 infinite alternate;
}

.grid-debug div.debug-updated {
    -webkit-animation: debugupdated 1s ease 0;
}
/* END - grid debug extension css*/


.pg-cell .OpportunityVersionValidationStatus-Accepted,
.pg-cell .OpportunityVersionValidationStatus-AutoAccepted {
    background-color: ForestGreen;
    border-radius: 5px;
    color: white;
    padding-left: 5px;
}

.pg-cell .OpportunityVersionValidationStatus-InProgress,
.pg-cell .OpportunityVersionValidationStatus-NotRequested,
.pg-cell .OpportunityVersionValidationStatus-Requested {
    background-color: Gold;
    border-radius: 5px;
    padding-left: 5px;
}

.pg-cell .OpportunityVersionValidationStatus-Rejected {
    background-color: OrangeRed;
    color: white;
    border-radius: 5px;
    padding-left: 5px;
}

.pg-cell .OpportunityVersionValidationStatus-Restarted,
.pg-cell .OpportunityVersionValidationStatus-Cancelled {
    background-color: Gray;
    border-radius: 5px;
    padding-left: 5px;
    color: white;
}

.pg-cell.pg-cell-invalid.pg-cell-validation-WARN {
    background-color: #F7CFA6 !important;
    border-color: #FA922A !important;
}

.pg-cell.pg-cell-invalid.pg-cell-validation-INFO {
    background-color: #E1DEF2 !important;
    border-color: #4848B9 !important;
    color: #4848B9
}

.pg-cell.pg-cell-invalid.pg-cell-validation-BLOCK,
.pg-cell.pg-cell-invalid.pg-cell-validation-ERROR {
    background-color: #f2dede !important;
    border-color: #b94a48 !important;
    color: #b94a48;
}

.error .pg-cell {
    background-color: #f2dede;
}

.error .pg-rowgroup.pg-scrolling {
    background-color: #f2dede;
}

.pg-filter-box > input.pg-filter-input {
    background-color: white !important;
    color: $body-color;
    border: none;
    border-top: 1px solid $pg-color-columnheader-border-light;
}

.pg-filter-with-option:before {
    position: absolute;
    left: 0px;
    top: 0px;
    content: "\e114";
    font-size: 0.8em;
}

.pg-cell-danger {
    background-color: #ff6e66;
}

.pg-sort-ascending .pg-sorter {
    border-color: transparent transparent $primary transparent;
}

.pg-sort-descending .pg-sorter {
    border-color: $primary transparent transparent transparent;
}

.pg-cell.pg-cell-invalid.pg-cell-validation-WARN {
    background-color: #F7CFA6 !important;
    border-color: #FA922A !important;
}

.pg-cell.pg-cell-invalid.pg-cell-validation-INFO {
    background-color: #E1DEF2 !important;
    border-color: #4848B9 !important;
    color: #4848B9
}

.pg-cell.pg-cell-invalid.pg-cell-validation-BLOCK,
.pg-cell.pg-cell-invalid.pg-cell-validation-ERROR {
    background-color: #f2dede !important;
    border-color: #b94a48 !important;
    color: #b94a48;
}
