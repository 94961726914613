/**
* Variables. Override bootstrap defaults and provide some of our own
*/

// theme colors
$prl-orange: #EB7F00;
$body-color: #424242;
$input-border-color: #bbd0d2;

$primary: #225378;
$secondary: #1595A3;
$info: $prl-orange;
$success: #28a771;
$danger: #dc3545;
$dark: #545454;
$light: #f8f9fa;

// general
$base-font-size: 13px;
$grid-gutter-width: 20px;
$prl-border-top: 10px solid $prl-orange;

// tabs
$color-tab-border: $secondary;
$color-tab-inactive: rgba($color-tab-border, 0.5);
$nav-tabs-link-active-bg: white;
$nav-tabs-border-color: $color-tab-border;
$nav-tabs-link-hover-border-color:  $color-tab-inactive $color-tab-inactive $nav-tabs-border-color;
$nav-tabs-link-active-border-color: $color-tab-border $color-tab-border $nav-tabs-link-active-bg;

// cards
$card-cap-bg: rgba($secondary, 0.1);
$card-border-color: $input-border-color;

// dropdowns
$dropdown-item-padding-y: 0.5rem;

//navbar
$navbar-padding-y: 0;

$link-color: $primary;

// powergrid
$pg-color-bg-columnheader: white;
$pg-color-columnheader-border: $secondary;
$pg-color-columnheader-border-light: #8ad0d8;
$pg-color-bg-cell: white;
$pg-color-border-cell: rgba(0, 0, 0, 0.05);
$pg-color-bg: #fbfbfb;
$pg-color-bg-cell-hover: #f1f1f1;
$pg-color-bg-cell-hover-editable: $secondary;
$pg-color-bg-cell-selected: #b0e4ea;
$pg-cell-selected-border: 1px solid $secondary;
