@import "pearlchain-variables";
@import "~bootstrap/scss/bootstrap";
@import "pearlchain-pg";

// --- general ---

html {
    font-size: $base-font-size;
}

#app {
    border-top: $prl-border-top;
}

.flex-justify-center {
    display: flex;
    justify-content: center;
}

// --- bootstrap ---

// modals

.modal-content {
    border-top: $prl-border-top;
}

// buttons

.btn-group .btn:not(:first-child) {
    border-left: 2px solid rgba(0, 0, 0, 0.05);
}

// navbar

.nav-tabs .nav-link {
    cursor: pointer;
}

.navbar-light .navbar-nav .nav-link {
    color: $body-color;
}

.link-decorated {
    cursor: pointer;
    text-decoration: underline;
}

// --- pearlchain ---

.prl-navbar {
    padding: 3px;
    padding-bottom: 5px;
}

.prl-navbar-breadcrumbs {
    background: none;
    .breadcrumb-item:not(:last-child) {
        cursor: pointer;
        text-decoration: underline;
    }
    .breadcrumb-item:last-child {
        font-weight: 700;
    }
}

.prl-nav-menu-dropdown a {
    color: $body-color;
};

// --- login form ---

.prl-login-container {
    max-width: 500px;
    height: auto !important;
}

.prl-login-container .card {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
    padding: 1.25rem;
    border-radius: 10px;
}

.prl-login-img {
    padding: 0 1.25rem;
    object-fit: contain;
}

.prl-login-page {
    align-items: center;
    background-color: #f3f3f3;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
}

.text-error {
    color: $danger;
    margin-top: 0.25rem;
}
